.CatalogMain {
  margin-top: 2rem;
  margin-left: 25rem; 
  margin-right: 5rem;
}

.catalogContainer1 {
  display: flex;
  margin: 4.7rem auto;
  flex-direction: column;
  justify-content: left; /* Horizontally center */
  align-items: start; /* Vertically center */
}

.catalogContainer1 p {
  font-size: 1.6rem;
}

.catalogContainer1 h2 {
  padding-bottom: 1rem;
}

.gallery {
  width: 100%;
  column-count: 3;
  column-gap: 25px;
  gap:25px
}

.singlePainting {
  width:100%; /* Adjust width for 3 items per row with gap */
  height: auto;
}

.singlePainting img {
  display: inline-block;
  width: 100%; /* Ensure the image fills the container */
  margin-bottom: 7px;
}

@media (max-width: 1024px) {
  .gallery {
    justify-content: center; /* Center items on smaller screens */
  }
}

@media (max-width: 768px) {
  .singlePainting {
    width: 100%; /* Adjust width for 2 items per row with gap on smaller screens */
  }
}


@media screen and (max-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr); /* 1 column for even smaller screens */
  }
}


/* Adjustments for small screens */
@media (max-width: 768px) {
  .CatalogMain {
    margin: 2rem 1.5rem;
  }

  .catalogContainer1 {
    display: flex;
    margin: 9rem auto;
    flex-direction: column;
    justify-content: center; /* Horizontally center */
    align-items: start; /* Vertically center */
  }
  
  .gallery {
    column-count: 1;
    width: 100%;
  }
}