.navbar {
  position: fixed;
  margin-bottom: 10rem;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px; /* Adjust the width as needed */
  background-color: white; /* Example background color */
  color: black; /* Example text color */
  padding: 20px;
  text-align: right;
  padding-top: 4.5rem;
}

.headerNEW h2 a {
  color: black;
  text-decoration: none;
  font-size: 2.2rem;
}

.headerNEW h2 {
  padding-bottom: 1.8rem;

}

.navbar .largeScreen, .navbar .smallercreensUL {
  list-style-type: none;
  z-index: 2;
  padding: 0; /* Remove default padding */
  margin: 0;
}

.navbar ul li a {
  color: black;
  text-decoration: none;
  font-size: 1.6rem;
}

.navbar a {
  color: black;
  text-decoration: none;
  font-size: 1.6rem;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 0; /* Ensure it appears behind dropdown menu */
}

.navbar ul li a .bold-a {
  color: red;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar ul li {
  padding-bottom: 1rem;
}

/* Navigation.module.css */

.active {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.smallercreens{
  display: none;
}

.buttonMenu {
  width: 100px;
  padding: 6px 0;
  text-align: center;
  border-radius: 10px;
  z-index: 0;
  font-weight: bold;
  border: 2px solid lightgray;
  background: transparent;
  color: black;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.buttonMenu a{
  text-decoration: none;
  color: black
}


.buttonMenu:hover{
  background-color: lightgray;
  color: white;
}



/* Adjustments for small screens */
@media (max-width: 768px) {
  .largeScreen{
    display: none
  }
  .smallercreens{
    display: block;
    z-index: 0;
  }

  .navbar .largeScreen, .navbar .smallercreensUL{
    padding-top: 1.3rem;

  }
  
  .navbar {
    text-align: left;
    height:fit-content;
    padding-top: 1rem;
    padding-bottom: 0.7rem;
    z-index: 2;
  }

  .withShadow{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* horizontal-offset vertical-offset blur-radius spread-radius color */

  }

  .headerNEW h2 {
    padding-bottom: 0.8rem;
  
  }


  
}
