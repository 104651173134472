.detailsMain {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 5rem;
  margin-left: 25rem; 
  height: 100vh;
}




.navbarDetails {
    position: fixed;
    top: 55rem;
    left: 2rem;
    width: 220px; /* Adjust the width as needed */
    background-color: white; /* Example background color */
    color: black; /* Example text color */
    padding: 20px;
    text-align: right;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1rem;

  }

  .headerNEWDetaild h2 a {
    color: black;
    text-decoration: none;
  }


  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%; 
  }

.image {
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain;
  z-index: 2;
}   


.detailsInfo{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 2;
  word-break:normal;
}

.detailsInfo p {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
}

.detailsContainer{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  height: 100vh;
}

.buttonsDiv{
  display: flex;
  gap: 20px;
}

.btnedit, .btndelete{
  width: 100px;
  padding: 15px 0;
  text-align: center;
  margin: 20px 10px;
  border-radius: 25px;
  z-index: 0;
  font-weight: bold;
  font-size: 1rem;
  border: 3px solid lightgray;
  background: transparent;
  color: black;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btnedit a{
  text-decoration: none;
  color: black
}

 .btndelete:hover{
  background-color: lightgray;
}
.btnedit:hover{
  background-color: lightgray;
}



/* Adjustments for small screens */
@media (max-width: 768px) {
  .detailsMain {
    margin: 2rem 1.5rem;
  }

  .imageContainer {
    display: flex;
    margin: 9rem auto;
    justify-content: center;
    align-items: center;
    height: 80%; 
  }

  .detailsContainer{
    z-index: 2;
  }
  .navbarDetails{
    text-align: left;
    top: 47rem;
    width: 220px;
    z-index: 2;
  }
}