.pressPageMain {
    margin-top: 2rem;
    margin-left: 25rem; 
  }
  
  .pressPageMain div {
    margin: 4.5rem auto;
    display: flex;
    flex-direction: column;
    justify-content: left; /* Horizontally center */
    align-items: flex-start; /* Vertically center */
    gap: 20px
  }
  

.pressPageMain div a{
    font-size: 1.6rem;
}

.pressPageMain div h3{
  font-size: 2.2em;
}


/* Adjustments for small screens */
@media (max-width: 768px) {
  .pressPageMain {
    margin: 2rem 1.5rem;
  }

  .pressPageMain div  {
    margin: 9rem auto;
    display: flex;
    flex-direction: column;
    justify-content: left; /* Horizontally center */
    align-items: flex-start; /* Vertically center */
    gap: 20px
  }
  
}