.homePageMain {
  margin-left: 21rem; 
  padding-left: 3rem;
  }
  
  .homePageMain div {
    /* margin: 4.5rem 0; */
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
  }

  .backgroundImage {
    width: 100%;
    height: 100vh; /* Set the height of the container to be the full viewport height */
    background-image: url('https://drive.google.com/thumbnail?id=1uShljueie9gG8qZsyBjpptpg0R_vuTRN&sz=w1000');
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
  }


.homePageMain img{
    width: 2000px; /* Adjust the width as needed */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
}

@media (max-width: 768px) {
  .homePageMain {
    margin-left: 0rem;
    padding-left: 0rem;
    padding-top: 10rem;
}
.homePageMain img{
  width: 100%; /* Adjust the width as needed */
  height: 100%; /* Automatically adjust height to maintain aspect ratio */
}

.backgroundImage {
  width: 100%;
  height: 100vh; /* Set the height of the container to be the full viewport height */
  background-image: url('https://drive.google.com/thumbnail?id=1uShljueie9gG8qZsyBjpptpg0R_vuTRN&sz=w1000');
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
}
}