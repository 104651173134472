.contactsMainPage {
  margin-top: 2rem;
  margin-left: 25rem;
}

.info {
  margin: 4.5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: left; /* Horizontally center */
  align-items: flex-start; /* Vertically center */
  gap: 20px;
}

.info a {
  font-size: 1.6rem;
}

.contactsMainPage div a {
  font-size: 1.6rem;
}

.contactsMainPage div h3 {
  font-size: 2.2em;
}

.icon {
  color: black;
  padding-right: 1rem;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

h2 {
  margin-bottom: 1rem;
}

.formGroup {
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.6rem;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical; /* Allow vertical resizing */
}

button[type="submit"] {
  background-color: lightgray;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: darkgray;
}

/* Adjustments for small screens */
@media (max-width: 768px) {
  .contactsMainPage {
    margin: 9rem 1.5rem;
  }

  form {
    width: 100%;
  }

  .info {
    word-break: break-word;
  }
}
