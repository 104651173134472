

.submitButton{
    width: 200px;
    padding: 15px 0;
    text-align: center;
    margin: 20px 10px;
    border-radius: 25px;
    z-index: 0;
    font-weight: bold;
    border: 3px solid lightgray;
    background: transparent;
    color: black;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform: translateY(-50px)
}

.submitButton span{
    background: lightgray;
    height: 100%;
    width: 0%;
    border-radius: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.5s;
}

.submitButton:hover span{
    width: 100%;;
}

.submitButton:hover{
    border: none
}


.hero{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(95px);

}

form{
    width: 150%;
    height: 100%;
    /* border: 2px solid #5aa8a0 ;
    border-radius: 25px; */
    padding: 2px


}

.registerbox{
    margin-bottom: 25px;
    position: relative;
    display: flex;
}

.registerboxInput{
    width: 80%;
    padding: 10px;
    outline: 0;
    border: 2px solid black;
    color: black;
    background: transparent;
    font-size: 15px;
}

.registerboxLabels{
    height: 100%;
    position: absolute;
    left: 0;
    top: -32px;
    padding: 10px;
    color: black;
    cursor: text;
    transition: 0.2s;
    font-size: 1.2rem;
}

.registerboxLabelsGenre{
    height: 100%;
    position: absolute;
    left: 0;
    top: -32px;
    padding: 10px;
    padding-bottom: 20px;
    color: black;
    cursor: text;
    transition: 0.2s;
}


input:focus{
    border: 4px solid black;
}

.radioLabels{
    font-size: 1.2rem;
    display: inline-block;
    position: inherit;
}

.radioButtons{
    width: 15%;
    align-items: center;
    color: black;
    display: flex;
    padding: 0px;
    padding-left: 20px;
    transform: translateY(-15px);
}

.radioInput{
    width: 10%;
    padding: 0px;
    outline: 0;
    padding-right: 0px;
    border: 2px solid black;
    color: black;
    background: transparent;
    font-size: 15px;
}

.registerboxInputDescription{
    width: 80%;
    padding: 10px;
    outline: 0;
    border: 2px solid black;
    color: black;
    background: transparent;
    font-size: 15px;
}


.errorMsg{
    position: absolute;
    width: 200px;
    height: auto;
    border: 3px solid white;
    border-style: dashed;
    background-color:transparent;
    transform: translateX(350px);
    padding: 10px;
    margin: 20px;
    box-sizing: border-box;
    display: block;
    justify-content: center;
    text-align: center;
}

.errorMsg h1{
    color: white;
    padding: 0px;
}
.errorMsg p{
    color: white;
    padding: 3px;
    word-break: normal;
    font-size: 20px;
    font-style: italic;
}

.registerbox1, .registerbox2{
    position: relative;
    display: flex;
    gap: 15px
}